import { Settings as defaults } from "./settings.default";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import { mergeWith } from "lodash";
import { PaymentTypeEnum } from "@application/types/PaymentType.enum";
import { LANGUAGE } from "@config/lang";
import { SEARCH_TYPES } from "@config/const/enums";
import { BsTiktok } from "react-icons/bs";

export const Settings = mergeWith(
  defaults,
  {
    endpoint: "https://admin.adriatours-ks.com/",
    languages: [LANGUAGE.SQ, LANGUAGE.EN],
    encodeUrl: false,
    hashRouter: true,
    defaultLanguage: "sq",
    links: {
      top: ["phone", "lang"],
      menu: ["b2b"],
    },
    menuLinks: [],
    googleAnalyticsTrackingId: "UA-168716001-1",
    contact: {
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.281581319488!2d21.152859876319155!3d42.65538801639221!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549f3c15409dc9%3A0xf237729c9ba884f4!2sAdria%20Tours!5e0!3m2!1sen!2smk!4v1697709059334!5m2!1sen!2smk",
      mapOnFooter: true,
    },
    social: [
      {
        name: "Facebook",
        Icon: AiFillFacebook,
        url: "https://www.facebook.com/adriatours.bulgaria",
      },
      {
        name: "Instagram",
        Icon: AiFillInstagram,
        url: "https://www.instagram.com/adriatours_/",
      },
      {
        name: "TikTok",
        Icon: BsTiktok,
        url: "https://www.tiktok.com/@adriatours",
      },
    ],
    search: {
      order: [SEARCH_TYPES.hotel, SEARCH_TYPES.charter],
      charter: {
        displayDepartures: true,
        extraDays: 1,
      },
      hotel: {
        displayDepartures: false,
        minBookingStartDays: 0,
      },
    },
    booking: {
      show_extend_hotel_night: false,
      passengers: {
        require_passport_no: true,
      },
      payments: [
        {
          type: PaymentTypeEnum.Cash,
          label: "payments.cash",
          public: true,
          agent: false,
        },
        {
          type: PaymentTypeEnum.CreditCard,
          label: "payments.cc",
          public: true,
          agent: false,
        },
        {
          type: PaymentTypeEnum.Invoice,
          label: "payments.inv",
          public: true,
          agent: true,
        },
      ],
    },
    iframe: {
      allowBooking: false,
    },
    b2b: {
      background: "/adr/b2b-bg-summer.png",
    },
  },
  (a, b) => (Array.isArray(b) ? b : undefined)
);
